(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Season
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Seasons', Seasons);

  function Seasons($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/competition/seasons');
  }
}());
